/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
}
@font-face {
  font-family: 'Hvd_comic_serif_pro';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.svg#hvd_comic_serif_pro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.HVDComicSerifPro {
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal;
}
@font-face {
  font-family: 'Bjola';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.svg#bjola') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Bjola {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
}
@font-face {
  font-family: 'Dimbo';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/dimbo.svg#dimbo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Dimbo {
  font-family: 'Dimbo', sans-serif;
  font-weight: normal;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.nicefont,
h1,
h2,
h3 {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.textContent {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.textContent h1,
h2.section-title,
.lower-content h2 {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  color: #22242a;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  .textContent h1,
  h2.section-title,
  .lower-content h2 {
    text-align: center;
  }
}
/*#endregion Fonts*/
form {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
body {
  background: #7aedfe;
  position: relative;
}
.outer-wrapper {
  overflow: hidden;
  max-width: 100vw;
  position: relative;
}
@media (min-width: 768px) {
  .outer-wrapper.outer-wrapper--alt .outer-wrapper--alt__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/top-wrapper-bg.svg");
    background-size: 100% 100%;
    padding-top: 68.4%;
    width: 100%;
  }
}
.outer-wrapper.outer-wrapper--alt .columns__wrapper-bg {
  background: transparent;
}
.outer-wrapper.outer-wrapper--alt .columns__container {
  background: #fff;
}
.outer-wrapper.outer-wrapper--alt .top-wrapper__bottom-bg,
.outer-wrapper.outer-wrapper--alt .columns__wrapper-bg-out {
  display: none;
}
.outer-wrapper.outer-wrapper--alt .products-intro {
  display: none;
}
.outer-wrapper.outer-wrapper--alt .top-wrapper {
  background-image: none;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 5px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #00b7fa;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
}
@media (min-width: 768px) {
  .top-wrapper {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/top-wrapper-bg.svg");
    background-size: 100% 100%;
  }
}
.top-wrapper__bottom-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}
.top-wrapper__stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;
  display: none;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
}
.top-wrapper__stars:before {
  content: "";
  display: block;
  padding-top: 30.82%;
  background-image: url(https://files.bookingonline.co.uk/image/upload/themes/029/stars@1x.png);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .top-wrapper__stars:before {
    background-image: url(https://files.bookingonline.co.uk/image/upload/themes/029/stars@2x.png);
  }
}
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding: 30px 15px;
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/top-wrapper-bg.svg");
    background-size: 100% 100%;
  }
  .header:before {
    display: block;
  }
  .header > * {
    max-width: 400px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 24.1%;
  }
}
.header__bg {
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
@media (max-width: 767px) {
  .header__bg {
    display: none;
  }
}
@media (min-width: 768px) {
  .header__bg {
    position: absolute;
    width: 96.91%;
    bottom: -7.43%;
    left: 0%;
  }
}
.header__container {
  padding: 0;
  z-index: 2;
}
.header__logo {
  display: none;
  -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.4));
  z-index: 5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .header__logo {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 32.25%;
    bottom: -8.45%;
    left: 31.51%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    position: relative;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 32.25%;
    bottom: 3.25%;
    left: 27.51%;
  }
}
.header__logo:hover {
  -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 23.53%;
    bottom: 26.01%;
    right: 5.86%;
  }
}
@media (max-width: 767px) {
  .header__call {
    position: relative;
    display: block;
    margin: 30px auto 0;
    width: 307px;
    height: 34px;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 30.21%;
    bottom: 5.41%;
    right: 5.13%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    position: relative;
    display: block;
    margin: 10px auto 0;
  }
}
@media (max-width: 767px) {
  .header__biha {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__biha {
    position: absolute;
    width: 4.8%;
    bottom: 48.99%;
    right: 18.89%;
  }
}
@media (max-width: 767px) {
  .header__biha {
    display: block;
    margin: 10px auto 0;
    width: 60px;
    height: 65px;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__tipe {
    position: absolute;
    width: 4.8%;
    bottom: 48.99%;
    right: 12.62%;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    display: block;
    margin: 10px auto 0;
    width: 62px;
    height: 65px;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__bcn {
    position: absolute;
    width: 7.25%;
    bottom: 48.99%;
    right: 4.23%;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    display: block;
    margin: 10px auto 0;
    width: 93px;
    height: 65px;
  }
}
.header__badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__badges {
    position: relative;
    margin: 40px auto 20px;
  }
}
@media (max-width: 767px) {
  .header__badge {
    -ms-flex-item-align: center;
        align-self: center;
    height: 65px;
    margin: 0 5px;
  }
}
@media (max-width: 767px) {
  .header__follow {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__follow {
    position: absolute;
    width: 18.65%;
    bottom: 25.68%;
    left: 6.43%;
  }
}
@media (max-width: 767px) {
  .header__follow {
    position: relative;
    display: block;
    margin: 10px auto 0;
    width: 312px;
    height: 34px;
  }
}
.header__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__social {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social {
    position: absolute;
    width: 18.65%;
    bottom: 6.76%;
    left: 6.43%;
  }
}
@media (max-width: 767px) {
  .header__social {
    max-width: 360px;
    margin: 10px auto;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
.header__social-link {
  width: calc(25% - 10px);
  display: block;
  margin: 0 5px;
}
@media (max-width: 767px) {
  .header__social-link {
    position: relative;
    width: auto;
  }
}
.header__social-link img {
  border: 3px solid #fff;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .header__social-link img {
    width: 70px;
    height: 70px;
  }
}
.toplinks {
  border-bottom-right-radius: 15px;
  position: relative;
}
.toplinks__inner {
  position: relative;
  padding: 0 15px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/toplinks-bg.svg");
  background-size: 100% 100%;
  z-index: 4;
}
.toplinks__container {
  padding: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 10px 5px;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #fff;
}
.toplinks__link {
  color: #fff;
  background: none;
  font-size: 1.1rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  display: block;
  border-radius: 10px;
  padding: 20px 5px 10px;
  position: relative;
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}
.toplinks__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 10px;
  z-index: -1;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #ffea3f;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.toplinks__link:hover:before,
.toplinks__link.active:before,
.toplinks__link:focus:before {
  opacity: 1;
}
.toplinks__dropdown {
  text-align: center;
  padding: 0;
  background-color: #e61717;
  background: -webkit-gradient(linear, left top, left bottom, from(#e61717), to(#e61717));
  background: linear-gradient(to bottom, #e61717, #e61717);
  min-width: 100%;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  font-size: 1.1rem;
  color: #fff !important;
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: transparent !important;
  color: #ffea3f !important;
}
.banner {
  position: relative;
  padding: 5px 5px 0;
  background: #fff;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
  /*&__welcome {
		position: absolute;
		width: 34.55%;
		bottom: 0;
		right: -20px;
		display: none;
		background-image: url(banner-welcome-bg.svg);
		background-size: 100% 100%;
		@media @desktop {
			display: block;
		}
		&:before {
			content: "";
			width: 100%;
			display: block;
			padding-bottom: 96.6%;
		}
	}
	&__welcome-title {
		position: absolute;
		top: 12%;
		left: 8%;
		width: 60%;
	}
	&__welcome-pointy {
		position: absolute;
		bottom: 0;
		right: -10px;
		width: 50%;
	}
	&__welcome-text {
		position: absolute;
		left: 8%;
		width: 49%;
		bottom: 10%;
		top: 30%;
		overflow: hidden;
		font-size: 0.9rem;
		color: #000;
	}*/
}
.banner #CarouselContainer {
  padding-top: 41.07%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner #CarouselContainer {
  overflow: visible !important;
}
.banner__container {
  padding: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner__container {
    text-align: center;
  }
}
.banner #CarouselPrevious,
.banner #CarouselNext {
  position: absolute;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/carousel-arrow.png");
  width: 50px;
  height: 60px;
  background-size: 100% 100%;
}
.banner #CarouselNext {
  right: -55px;
  top: 50%;
  -webkit-transform: translateY(-50%) scaleX(-1);
          transform: translateY(-50%) scaleX(-1);
}
.banner #CarouselPrevious {
  left: -55px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.products-gallery {
  position: relative;
  background-size: 100% 100%;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
  margin-bottom: 20px;
  padding: 6.5% 0 5%;
  margin-top: -5%;
  margin-left: -20px;
  margin-right: -20px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/product-gallery-bg_1x.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .products-gallery {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/product-gallery-bg_2x.png");
  }
}
@media (min-width: 768px) {
  .products-gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 3.5% 3% 3%;
    margin-top: -3%;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 1330px) {
  .products-gallery {
    margin-left: -40px;
    margin-right: -40px;
  }
}
.products-gallery__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 768px) {
  .products-gallery__left {
    width: 80%;
  }
}
.products-gallery .slick-list {
  width: 100%;
}
.products-gallery__slide {
  position: relative;
  margin: 0 5px;
  border: 2px solid #fff;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
}
.products-gallery__link {
  position: relative;
  display: block;
}
.products-gallery__link:before {
  content: "";
  display: block;
  padding-top: 74.15%;
}
.products-gallery__link img {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}
.weather-link {
  display: none;
  position: relative;
  width: 19%;
  height: 110px;
  background: #47d3f9;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 5px;
}
@media (min-width: 768px) {
  .weather-link {
    display: block;
  }
}
.weather-link__image {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 95%;
  left: 0;
}
.featured-categories {
  margin: 15px auto;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.featured-categories__gallery {
  width: 49%;
  position: relative;
  background-size: 100% 100%;
  margin-bottom: 15px;
}
.featured-categories__gallery #CarouselContainer {
  padding-top: 74.67%;
  overflow: hidden;
}
.featured-categories__gallery #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.featured-categories__gallery #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.featured-categories__gallery #CarouselContainer .carousel-inner .item a img,
.featured-categories__gallery #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
@media (max-width: 767px) {
  .featured-categories__gallery {
    width: 49.5%;
  }
}
@media (max-width: 525px) {
  .featured-categories__gallery {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .featured-categories__gallery {
    width: 49.5%;
  }
}
@media (min-width: 992px) {
  .featured-categories__gallery {
    width: 24.5%;
    margin-bottom: 0;
  }
}
.featured-categories__gallery--01 {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/featured-category-bg-01.svg");
}
.featured-categories__gallery--02 {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/featured-category-bg-02.svg");
}
.featured-categories__gallery--03 {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/featured-category-bg-03.svg");
}
.featured-categories__gallery--04 {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/featured-category-bg-04.svg");
}
.featured-categories__gallery-inner {
  padding: 6% 9.67%;
  padding-bottom: 2%;
}
.featured-categories__image-panel {
  overflow: hidden;
  position: relative;
}
.featured-categories__image-panel:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 74.01%;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.featured-categories__title {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  color: #fff;
  padding: 0 10px;
  font-size: 1.3rem;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  margin-top: 5%;
}
.featured-categories .carousel {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .featured-categories .carousel {
    margin-bottom: 15px;
  }
}
.columns {
  border-radius: 15px;
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.columns__wrapper {
  position: relative;
}
.columns__wrapper-bg {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background: #fff;
}
.columns__wrapper-bg-out {
  position: absolute;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
.columns__left {
  position: relative;
  padding: 1px 10px;
  width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
  .columns__left {
    width: 300px;
  }
}
.columns__main {
  position: relative;
}
@media (min-width: 768px) {
  .columns__main {
    width: calc(100% - 200px);
  }
}
@media (min-width: 992px) {
  .columns__main {
    width: calc(100% - 300px);
  }
}
.columns > div {
  position: relative;
}
@media (max-width: 767px) {
  .columns__container {
    background: #fff;
  }
}
.category-links {
  margin-bottom: 20px;
  position: relative;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  margin-top: 10px;
  text-align: left;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1rem;
  width: 100%;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  min-height: 57.5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 3% 10px 8%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
@media (min-width: 992px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 1.3rem;
  }
}
.category-links__link:before,
.category-links__child-link:before,
.category-links__link:after,
.category-links__child-link:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}
.category-links__link:before,
.category-links__child-link:before {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link.active,
.category-links__child-link.active,
.category-links__link:focus,
.category-links__child-link:focus {
  color: #fff;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link.active:before,
.category-links__child-link.active:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.category-links__link:nth-of-type(6n + 1):before,
.category-links__child-link:nth-of-type(6n + 1):before,
.category-links__link:nth-of-type(6n + 1) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 1) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-shadow-pink.svg");
}
.category-links__link:nth-of-type(6n + 1):after,
.category-links__child-link:nth-of-type(6n + 1):after,
.category-links__link:nth-of-type(6n + 1) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 1) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-button-pink.svg");
}
.category-links__link:nth-of-type(6n + 2):before,
.category-links__child-link:nth-of-type(6n + 2):before,
.category-links__link:nth-of-type(6n + 2) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 2) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-shadow-orange.svg");
}
.category-links__link:nth-of-type(6n + 2):after,
.category-links__child-link:nth-of-type(6n + 2):after,
.category-links__link:nth-of-type(6n + 2) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 2) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-button-orange.svg");
}
.category-links__link:nth-of-type(6n + 3):before,
.category-links__child-link:nth-of-type(6n + 3):before,
.category-links__link:nth-of-type(6n + 3) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 3) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-shadow-blue.svg");
}
.category-links__link:nth-of-type(6n + 3):after,
.category-links__child-link:nth-of-type(6n + 3):after,
.category-links__link:nth-of-type(6n + 3) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 3) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-button-blue.svg");
}
.category-links__link:nth-of-type(6n + 4):before,
.category-links__child-link:nth-of-type(6n + 4):before,
.category-links__link:nth-of-type(6n + 4) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 4) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-shadow-purple.svg");
}
.category-links__link:nth-of-type(6n + 4):after,
.category-links__child-link:nth-of-type(6n + 4):after,
.category-links__link:nth-of-type(6n + 4) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 4) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-button-purple.svg");
}
.category-links__link:nth-of-type(6n + 5):before,
.category-links__child-link:nth-of-type(6n + 5):before,
.category-links__link:nth-of-type(6n + 5) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 5) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-shadow-green.svg");
}
.category-links__link:nth-of-type(6n + 5):after,
.category-links__child-link:nth-of-type(6n + 5):after,
.category-links__link:nth-of-type(6n + 5) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 5) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-lr-button-green.svg");
}
.category-links__link:nth-of-type(6n + 6):before,
.category-links__child-link:nth-of-type(6n + 6):before,
.category-links__link:nth-of-type(6n + 6) + div .category-links__child-link:before,
.category-links__child-link:nth-of-type(6n + 6) + div .category-links__child-link:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-shadow-red.svg");
}
.category-links__link:nth-of-type(6n + 6):after,
.category-links__child-link:nth-of-type(6n + 6):after,
.category-links__link:nth-of-type(6n + 6) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(6n + 6) + div .category-links__child-link:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/catlink-rl-button-red.svg");
}
.category-links__child-link {
  margin-left: 10%;
  width: 90%;
}
/*#region Other Left Column Stuff*/
.left-block {
  text-align: center;
  margin-top: auto;
}
.left-block__inner {
  padding: 5px;
  position: relative;
}
.left-block__logo {
  width: 90%;
  left: 5%;
  margin-top: 16%;
}
.left-block__phone-alt {
  display: none;
}
.left-badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.left-badges__badge {
  margin: 0 1% 10px;
  width: 43%;
  -ms-flex-item-align: center;
      align-self: center;
}
/*#endregion Other Left Column Stuff*/
/*#region Castle Panels*/
.page-listings {
  position: relative;
  background: transparent !important;
}
@media (min-width: 768px) {
  .page-listings__inner {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .page-listings .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .page-listings .row > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}
#PageListings {
  margin-top: 35px;
}
@media (max-width: 420px) {
  #PageListings > div {
    width: 100%;
  }
}
.pageContent {
  margin-bottom: 0;
  padding-bottom: 15px;
}
.category-title {
  text-align: center;
  color: #e85555;
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  padding: 5px 10px;
  font-size: 3rem;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
}
.castlePanel {
  width: 100%;
  border: none;
  padding: 0 10px 10px;
  margin: 0 0 20px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel .castleSubtitle {
  display: none;
}
.castlePanel .SingleImagePanel {
  width: 100%;
  left: 0;
  background: transparent;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 10px 0 0;
  position: relative;
  height: auto;
  padding-top: 0;
  background: #d1e9ff;
  background-size: 100% 100%;
  border-radius: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}
@supports (mix-blend-mode:multiply) {
  .castlePanel .SingleImagePanel img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    top: 0 !important;
    left: 0 !important;
  }
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  position: relative;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  background-size: 100% 100%;
  font-size: 1.1rem;
  position: relative;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  text-align: center;
  padding: 0 5px;
  margin-top: 10px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 1rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.castlePanel .castleCheckBook {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  position: relative;
  height: auto;
  border-radius: 25px;
  border: 2px solid #fff;
  padding: 8px 5px;
  -webkit-transition: all 0.3s 0.1s;
  transition: all 0.3s 0.1s;
  background-color: #ff9020;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9020), to(#ff5300));
  background: linear-gradient(to bottom, #ff9020, #ff5300);
  font-size: 0.9rem;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 10px;
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #EA2127;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 195px;
  display: inline-block;
  margin: 10px 5px;
  float: none;
}
.testimonials__wrapper {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/testimonials-wrapper-bg.svg");
  position: relative;
  background-size: 100% 100%;
}
.testimonials__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.testimonials__title-text {
  color: #fff;
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  font-size: 4.3rem;
  text-transform: uppercase;
  margin: 0 2%;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
}
@media (max-width: 767px) {
  .testimonials__title-text {
    font-size: 8vw;
  }
}
.testimonials__stars {
  margin: 0 2%;
  max-width: 25%;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
}
@media (max-width: 767px) {
  .testimonials__stars {
    display: none;
  }
}
.testimonials__stars--right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.testimonials__inner {
  margin-top: 70px;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .testimonials__inner {
    padding: 0 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.testimonials__carousel {
  padding: 40px;
  text-align: center;
  position: relative;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .testimonials__carousel {
    width: 45%;
  }
}
.testimonials__carousel:before,
.testimonials__carousel:after {
  content: "";
  background-image: url("https://files.bookingonline.co.uk/image/upload/themes/029/quote.svg");
  background-size: 100% 100%;
  position: absolute;
  width: 75px;
  height: 60px;
  z-index: 1;
}
.testimonials__carousel:before {
  top: -30px;
  left: -30px;
}
.testimonials__carousel:after {
  bottom: -30px;
  right: -30px;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
.testimonials__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}
.testimonials__carousel-inner,
.testimonials__item {
  height: 100%;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.testimonials__text {
  color: #000;
}
.testimonials__author {
  color: #000;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.footer__wrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: white;
  margin: 0 5px 10px;
  display: inline-block;
  padding: 0 8px;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN img {
  margin: 0 auto;
}
#BouncyCastleHireLink {
  color: white;
  font-weight: 700;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}
@-webkit-keyframes bounceInDownSmall {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInDownSmall {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-7-26 15:17:26
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-5.6px);
            transform: translateY(-5.6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-1.6px);
            transform: translateY(-1.6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-5.6px);
            transform: translateY(-5.6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-1.6px);
            transform: translateY(-1.6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
.splitter.animated.anim-top .char {
  -webkit-animation: bounce-in-top 1s both;
          animation: bounce-in-top 1s both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
          animation-delay: calc(60ms * var(--char-index));
}
.splitter.animated.anim-right .char {
  -webkit-animation: fadeInRight 1s both;
          animation: fadeInRight 1s both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
          animation-delay: calc(30ms * var(--char-index));
}
/*#endregion Animations*/
