@import "../../Autobopr/Assets/functions.less";
@import (inline) "../../Autobopr/Assets/utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1329px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #00b7fa;
@castleDetailsColor: #EA2127;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/029/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/029/";

/*#region Fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700;
}

.font-HvdComicSerifPro;

.HVDComicSerifPro {
	font-family: 'Hvd_comic_serif_pro',sans-serif;
	font-weight: normal;
}

.font-Bjola;

.Bjola {
	font-family: 'Bjola', sans-serif;
	font-weight: normal;
}

.font-Dimbo;

.Dimbo {
	font-family: 'Dimbo', sans-serif;
	font-weight: normal;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont, h1, h2, h3 {
	.OpenSansBold;
}

.textContent {
	font-size: 1rem;

	@media @mobile {
		text-align: center;
	}
}

.textContent h1, h2.section-title, .lower-content h2 {
	.Bjola;
	font-size: 2.5rem;
	color: #22242a;
	text-transform: uppercase;
	text-align: center;

	@media @mobile {
		text-align: center;
	}
}
/*#endregion Fonts*/
form {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

body {
	background: #7aedfe;
	position: relative;
}

.outer-wrapper {
	overflow: hidden;
	max-width: 100vw;
	position: relative;

	&.outer-wrapper--alt {
		.outer-wrapper--alt__bg {
			@media @tablet {
				position: absolute;
				top: 0;
				left: 0;
				background-image: url("@{cloudUrl}top-wrapper-bg.svg");
				background-size: 100% 100%;
				padding-top: 68.4%;
				width: 100%;
			}
		}

		.columns__wrapper-bg {
			background: transparent;
		}

		.columns__container {
			background: #fff;
		}

		.top-wrapper__bottom-bg, .columns__wrapper-bg-out {
			display: none;
		}

		.products-intro {
			display: none;
		}

		.top-wrapper {
			background-image: none;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 5px;
	}

	@media @tablet {
		width: 100%;
		padding: 0 10px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	//.Gradient(#6900ff,#9700ff);
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;

	@media @tablet {
		background-image: url("@{cloudUrl}top-wrapper-bg.svg");
		background-size: 100% 100%;
	}

	&__bottom-bg {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&__stars {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		pointer-events: none;
		display: none;
		animation-duration: 2.5s;

		&:before {
			content: "";
			display: block;
			padding-top: 30.82%;
			.BackgroundImg("@{cloudUrlNoAuto}stars",png);
		}
	}
}

.header {
	@width: 1228px;
	@height: 296px;
	text-align: center;
	position: relative;

	@media @mobile {
		padding: 30px 15px;
		background-image: url("@{cloudUrl}top-wrapper-bg.svg");
		background-size: 100% 100%;

		&:before {
			display: block;
		}

		& > * {
			max-width: 400px;
		}
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__bg {
		.TopBarItem(1190,@width,@height,none,-22,left,0);
		filter: drop-shadow(0 0 5px rgba(0,0,0,0.3));
	}

	&__container {
		padding: 0;
		z-index: 2;
	}

	&__logo {
		.TopBarItem(396,@width,@height,auto,-25,left,387);
		display: none;
		filter: drop-shadow(0 2px 5px rgba(0,0,0,.4));
		z-index: 5;
		transition: all 0.3s;

		@media @mobile {
			position: relative;
		}

		@media @tablet {
			position: absolute;
			width: 32.25%;
			bottom: 3.25%;
			left: 27.51%;
		}

		&:hover {
			filter: drop-shadow(0 0 3px rgba(0,0,0,.1));
		}
	}

	&__call {
		.TopBarItem(289,@width,@height,auto,77,right,72);

		@media @mobile {
			position: relative;
			display: block;
			margin: 30px auto 0;
			width: 307px;
			height: 34px;
		}
	}

	&__phone {
		.TopBarItem(371,@width,@height,auto,16,right,63);

		@media @mobile {
			position: relative;
			display: block;
			margin: 10px auto 0;
		}
	}

	&__biha {
		.TopBarItem(59,@width,@height,auto,145,right,232);

		@media @mobile {
			display: block;
			margin: 10px auto 0;
			width: 60px;
			height: 65px;
		}
	}

	&__tipe {
		.TopBarItem(59,@width,@height,auto,145,right,155);

		@media @mobile {
			display: block;
			margin: 10px auto 0;
			width: 62px;
			height: 65px;
		}
	}

	&__bcn {
		.TopBarItem(89,@width,@height,auto,145,right,52);

		@media @mobile {
			display: block;
			margin: 10px auto 0;
			width: 93px;
			height: 65px;
		}
	}

	&__badges {
		display: flex;
		justify-content: center;

		@media @mobile {
			position: relative;
			margin: 40px auto 20px;
		}
	}

	&__badge {
		@media @mobile {
			align-self: center;
			height: 65px;
			margin: 0 5px;
		}
	}

	&__follow {
		.TopBarItem(229,@width,@height,auto,76,left,79);

		@media @mobile {
			position: relative;
			display: block;
			margin: 10px auto 0;
			width: 312px;
			height: 34px;
		}
	}

	&__social {
		.TopBarItem(229,@width,@height,auto,20,left,79);
		display: flex;
		justify-content: center;

		@media @mobile {
			max-width: 360px;
			margin: 10px auto;
			justify-content: space-around;
		}
	}

	&__social-link {
		width: ~'calc(25% - 10px)';
		display: block;
		margin: 0 5px;

		@media @mobile {
			position: relative;
			width: auto;
		}

		img {
			border: 3px solid #fff;
			border-radius: 50%;

			@media @mobile {
				width: 70px;
				height: 70px;
			}
		}
	}
}

.toplinks {
	border-bottom-right-radius: 15px;
	position: relative;

	&__inner {
		position: relative;
		padding: 0 15px;
		//edit fill in svg
		background-image: url("@{cloudUrl}toplinks-bg.svg");
		background-size: 100% 100%;
		z-index: 4;
	}

	&__container {
		padding: 0;
	}

	&__nav {
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;
		padding: 10px 5px;

		&.open .dropdown-toggle:focus {
			color: #fff;
		}
	}

	&__link {
		color: #fff;
		background: none;
		font-size: 1.1rem;
		.transition(all 0.3s);
		white-space: nowrap;
		display: block;
		border-radius: 10px;
		padding: 20px 5px 10px;
		position: relative;
		.HVDComicSerifPro;
		text-shadow: 0 1px 1px rgba(0,0,0,0.5);

		&:before {
			.StickToEdges;
			opacity: 0;
			transition: all 0.3s;
			border-radius: 10px;
			z-index: -1;
		}

		&:hover, &.active, &:focus {
			color: #ffea3f;
			text-shadow: 2px 2px rgba(0,0,0,0.2);

			&:before {
				opacity: 1;
			}
		}
	}

	&__dropdown {
		text-align: center;
		padding: 0;
		.Gradient(#e61717,#e61717);
		min-width: 100%;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		font-size: 1.1rem;
		color: #fff !important;
		.HVDComicSerifPro;

		&:hover, &:focus, &.active {
			background-color: transparent !important;
			color: #ffea3f !important;
		}
	}
}

.banner {
	.BannerPanelSet(41.07%);
	position: relative;
	padding: 5px 5px 0;
	background: #fff;
	filter: drop-shadow(0 0 10px rgba(0,0,0,0.3));

	#CarouselContainer {
		overflow: visible !important;
	}

	&__container {
		padding: 0;
		z-index: 1;

		@media @mobile {
			text-align: center;
		}
	}

	#CarouselPrevious, #CarouselNext {
		position: absolute;
		background-image: url("@{cloudUrl}carousel-arrow.png");
		width: 50px;
		height: 60px;
		background-size: 100% 100%;
	}

	#CarouselNext {
		right: -55px;
		top: 50%;
		transform: translateY(-50%) scaleX(-1);
	}

	#CarouselPrevious {
		left: -55px;
		.TransformMiddleV;
	}
	/*&__welcome {
		position: absolute;
		width: 34.55%;
		bottom: 0;
		right: -20px;
		display: none;
		background-image: url(banner-welcome-bg.svg);
		background-size: 100% 100%;
		@media @desktop {
			display: block;
		}
		&:before {
			content: "";
			width: 100%;
			display: block;
			padding-bottom: 96.6%;
		}
	}
	&__welcome-title {
		position: absolute;
		top: 12%;
		left: 8%;
		width: 60%;
	}
	&__welcome-pointy {
		position: absolute;
		bottom: 0;
		right: -10px;
		width: 50%;
	}
	&__welcome-text {
		position: absolute;
		left: 8%;
		width: 49%;
		bottom: 10%;
		top: 30%;
		overflow: hidden;
		font-size: 0.9rem;
		color: #000;
	}*/
}

.products-gallery {
	position: relative;
	background-size: 100% 100%;
	filter: drop-shadow(0 5px 5px rgba(0,0,0,0.3));
	margin-bottom: 20px;
	padding: 6.5% 0 5%;
	margin-top: -5%;
	margin-left: -20px;
	margin-right: -20px;
	background-image: url("@{cloudUrl}product-gallery-bg_1x.png");

	@media @retinaMedia {
		background-image: url("@{cloudUrl}product-gallery-bg_2x.png");
	}

	@media @tablet {
		display: flex;
		justify-content: space-between;
		padding: 3.5% 3% 3%;
		margin-top: -3%;
		margin-left: -20px;
		margin-right: -20px;
	}

	@media @desktop {
		margin-left: -40px;
		margin-right: -40px;
	}

	&__left {
		display: flex;
		align-items: center;

		@media @tablet {
			width: 80%;
		}
	}

	.slick-list {
		width: 100%;
	}

	&__slide {
		position: relative;
		margin: 0 5px;
		border: 2px solid #fff;
		background: #fff;
		overflow: hidden;
		border-radius: 10px;
	}

	&__link {
		position: relative;
		display: block;

		&:before {
			content: "";
			display: block;
			padding-top: 74.15%;
		}

		img {
			position: absolute;
			top: 0 !important;
			left: 0 !important;
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
}

.weather-link {
	display: none;
	position: relative;

	@media @tablet {
		display: block;
	}

	width: 19%;
	height: 110px;
	background: #47d3f9;
	border: 3px solid #fff;
	border-radius: 5px;
	padding: 5px;

	&__image {
		.TransformMiddleV;
		width: 95%;
		left: 0;
	}
}

.featured-categories {
	margin: 15px auto;
	padding: 0 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__gallery {
		width: 49%;
		position: relative;
		.BannerPanelSet(74.67%);
		background-size: 100% 100%;
		margin-bottom: 15px;

		@media @mobile {
			width: 49.5%;
		}

		@media (max-width:525px) {
			width: 100%;
		}

		@media @sm {
			width: 49.5%;
		}

		@media @md {
			width: 24.5%;
			margin-bottom: 0;
		}

		&--01 {
			background-image: url("@{cloudUrl}featured-category-bg-01.svg");
		}

		&--02 {
			background-image: url("@{cloudUrl}featured-category-bg-02.svg");
		}

		&--03 {
			background-image: url("@{cloudUrl}featured-category-bg-03.svg");
		}

		&--04 {
			background-image: url("@{cloudUrl}featured-category-bg-04.svg");
		}
	}

	&__gallery-inner {
		padding: 6% 9.67%;
		padding-bottom: 2%;
	}

	&__image-panel {
		overflow: hidden;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 74.01%;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__title {
		.Bjola;
		color: #fff;
		padding: 0 10px;
		font-size: 1.3rem;
		text-align: center;
		text-shadow: 0 1px rgba(0,0,0,0.3);
		pointer-events: none;
		margin-top: 5%;
	}

	.carousel {
		margin: 0 auto;

		@media @mobile {
			margin-bottom: 15px;
		}
	}
}

.columns {
	border-radius: 15px;
	position: relative;
	z-index: 1;
	padding-bottom: 80px;

	@media @tablet {
		display: flex;
	}

	&__wrapper {
		position: relative;

		&-bg {
			.StickToEdges;
			background: #fff;
		}

		&-bg-out {
			position: absolute;
			transform: scale(-1);
		}
	}

	&__left {
		position: relative;
		padding: 1px 10px;
		width: 200px;
		display: flex;
		flex-direction: column;

		@media @md {
			width: 300px;
		}
	}

	&__main {
		@media @sm {
			width: ~'calc(100% - 200px)';
		}

		@media @md {
			width: ~'calc(100% - 300px)';
		}

		position: relative;
	}

	& > div {
		position: relative;
	}

	&__container {
		@media @mobile {
			background: #fff;
		}
	}
}

.category-links {
	margin-bottom: 20px;
	position: relative;

	&__link, &__child-link {
		position: relative;
		margin-top: 10px;
		text-align: left;
		.PTSansNarrowBold;
		text-shadow: 1px 1px 1px #000;
		color: white;
		transition: all 0.3s;
		font-size: 1rem;
		width: 100%;
		transform: translateY(1px);
		min-height: 57.5px;
		align-items: center;
		padding: 10px 3% 10px 8%;
		display: flex;
		justify-content: left;

		@media @md {
			font-size: 1.3rem;
		}

		&:before, &:after {
			.StickToEdges;
			background-size: 100% 100%;
			z-index: -1;
		}

		&:before {
			transition: all 0.3s;
			transform: translateY(-1px);
		}

		&:hover, &.active, &:focus {
			color: #fff;
			transform: translateY(5px);

			&:before {
				transform: translateY(-5px);
			}
		}

		&:nth-of-type(6n + 1), &:nth-of-type(6n + 1) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-lr-shadow-pink.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-lr-button-pink.svg");
			}
		}

		&:nth-of-type(6n + 2), &:nth-of-type(6n + 2) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-rl-shadow-orange.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-rl-button-orange.svg");
			}
		}

		&:nth-of-type(6n + 3), &:nth-of-type(6n + 3) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-lr-shadow-blue.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-lr-button-blue.svg");
			}
		}

		&:nth-of-type(6n + 4), &:nth-of-type(6n + 4) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-rl-shadow-purple.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-rl-button-purple.svg");
			}
		}

		&:nth-of-type(6n + 5), &:nth-of-type(6n + 5) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-lr-shadow-green.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-lr-button-green.svg");
			}
		}

		&:nth-of-type(6n + 6), &:nth-of-type(6n + 6) + div .category-links__child-link {
			&:before {
				background-image: url("@{cloudUrl}catlink-rl-shadow-red.svg");
			}

			&:after {
				background-image: url("@{cloudUrl}catlink-rl-button-red.svg");
			}
		}
	}

	&__child-link {
		margin-left: 10%;
		width: 90%;
	}
}
/*#region Other Left Column Stuff*/
.left-block {
	text-align: center;
	margin-top: auto;

	&__inner {
		padding: 5px;
		position: relative;
	}

	&__logo {
		width: 90%;
		left: 5%;
		margin-top: 16%;
	}

	&__phone-alt {
		display: none;
	}
}

.left-badges {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&__badge {
		align-self: center;
		margin: 0 1% 10px;
		width: 43%;
		align-self: center;
	}
}
/*#endregion Other Left Column Stuff*/
/*#region Castle Panels*/
.page-listings {
	position: relative;
	background: transparent !important;

	&__inner {
		@media @tablet {
			padding: 20px;
		}
	}

	@media @mobile {
		.row {
			margin-left: -5px;
			margin-right: -5px;

			& > div {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}

#PageListings {
	margin-top: 35px;

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.pageContent {
	margin-bottom: 0;
	padding-bottom: 15px;
}

.category-title {
	text-align: center;
	color: #e85555;
	.Bjola;
	padding: 5px 10px;
	font-size: 3rem;
	text-shadow: 2px 2px 1px rgba(0,0,0,0.3);
}

.castlePanel {
	width: 100%;
	border: none;
	padding: 0 10px 10px;
	margin: 0 0 20px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	box-shadow: 0 0 2px rgba(0,0,0,0.3);
	display: flex;
	flex-direction: column;
	border-radius: 5px;

	@media @mobile {
		text-align: center;
	}

	&:hover {
		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.castleSubtitle {
		display: none;
	}

	.SingleImagePanel {
		width: 100%;
		left: 0;
		background: transparent;
		order: 1;
		margin: 10px 0 0;
		position: relative;
		height: auto;
		padding-top: 0;
		background: #d1e9ff;
		background-size: 100% 100%;
		border-radius: 0;

		&:before {
			content: "";
			width: 100%;
			padding-top: 100%;
			display: block;
		}

		img {
			@supports

			(mix-blend-mode:multiply) {
				width: 100%;
				height: 100%;
				object-fit: cover;
				top: 0 !important;
				left: 0 !important;
			}
		}
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		order: 2;

		a {
			color: #404040;
			position: relative;
			.OpenSansBold;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		background-size: 100% 100%;
		font-size: 1.1rem;
		position: relative;
		order: 3;
		text-align: center;
		padding: 0 5px;
		margin-top: 10px;
		.OpenSansBold;

		span.oldprice {
			font-size: 1rem;
		}

		span.newprice {
			font-size: 0.8rem;
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.castleCheckBook {
		order: 4;
		position: relative;
		height: auto;
		border-radius: 25px;
		border: 2px solid #fff;
		padding: 8px 5px;
		transition: all 0.3s 0.1s;
		.Gradient(#ff9020,#ff5300);
		font-size: 0.9rem;
		text-shadow: 1px 1px 0 rgba(0,0,0,0.3);
		.HVDComicSerifPro;
		text-transform: uppercase;
		margin-top: 10px;
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 195px;
		display: inline-block;
		margin: 10px 5px;
		float: none;
	}
}

.testimonials {
	&__wrapper {
		background-image: url("@{cloudUrl}testimonials-wrapper-bg.svg");
		position: relative;
		background-size: 100% 100%;
	}

	&__title {
		display: flex;
		align-items: center;
	}

	&__title-text {
		color: #fff;
		.Bjola;
		font-size: 4.3rem;
		text-transform: uppercase;
		margin: 0 2%;
		text-align: center;
		align-items: center;
		filter: drop-shadow(0 5px 5px rgba(0,0,0,.3));

		@media @mobile {
			font-size: 8vw;
		}
	}

	&__stars {
		margin: 0 2%;
		max-width: 25%;
		filter: drop-shadow(0 5px 5px rgba(0,0,0,.3));

		@media @mobile {
			display: none;
		}

		&--right {
			transform: scaleX(-1);
		}
	}

	&__inner {
		margin-top: 70px;
		padding: 0 30px;

		@media @tablet {
			padding: 0 60px;
			display: flex;
			justify-content: space-between;
		}
	}

	&__carousel {
		padding: 40px;
		text-align: center;
		position: relative;
		margin-bottom: 60px;

		@media @tablet {
			width: 45%;
		}

		&:before, &:after {
			content: "";
			// edit fill in SVG
			background-image: url("@{cloudUrlNoAuto}quote.svg");
			background-size: 100% 100%;
			position: absolute;
			width: 75px;
			height: 60px;
			z-index: 1;
		}

		&:before {
			top: -30px;
			left: -30px;
		}

		&:after {
			bottom: -30px;
			right: -30px;
			transform: scale(-1);
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: fill;
	}

	&__carousel-inner, &__item {
		height: 100%;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	&__text {
		color: #000;
	}

	&__author {
		color: #000;
		.OpenSansBold;
	}
}

.footer {
	&__wrapper {
		background-color: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row {
		@media @tablet {
			display: flex;
			align-items: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: white;
			margin: 0 5px 10px;
			display: inline-block;
			padding: 0 8px;
		}
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}
}

#BouncyCastleHireLink {
	color: white;
	font-weight: 700;
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}


@keyframes bounceInDownSmall {
	from, 60%, 75%, 90%, to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -30px, 0);
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}

	75% {
		transform: translate3d(0, -10px, 0);
	}

	90% {
		transform: translate3d(0, 5px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}
/* ----------------------------------------------
 * Generated by Animista on 2019-7-26 15:17:26
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@keyframes bounce-in-top {
	0% {
		transform: translateY(-100px);
		animation-timing-function: ease-in;
		opacity: 0;
	}

	38% {
		transform: translateY(0);
		animation-timing-function: ease-out;
		opacity: 1;
	}

	55% {
		transform: translateY(-13px);
		animation-timing-function: ease-in;
	}

	72% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}

	81% {
		transform: translateY(-5.6px);
		animation-timing-function: ease-in;
	}

	90% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}

	95% {
		transform: translateY(-1.6px);
		animation-timing-function: ease-in;
	}

	100% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}
}

.splitter.animated.anim-top {
	.char {
		animation: bounce-in-top 1s both;
		animation-delay: calc(60ms * var(--char-index));
	}
}

.splitter.animated.anim-right {
	.char {
		animation: fadeInRight 1s both;
		animation-delay: calc(30ms * var(--char-index));
	}
}
/*#endregion Animations*/
